.background-imagePost-myresume {
  background-image: url("../../../../../public/images/acquiro-element-bg.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .header-fixed + .jobposting-banner {
    padding-top: 90px;
  }

  .myresume-body {
    margin: 50px auto;
    padding: 0 auto;
    gap: 50px;
    display: flex;
    flex-direction: row;

    .my-resume-body-inner {
      gap: 50px;
      display: flex;
      width: 100%;

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }
    }

    .sidebar-container {
      top: 100px;
      position: sticky;
    }

    .company-profile-container {
      width: 972.38px;
      height: 100%;

      // display: inline-block;

      @media (max-width: 1440px) {
        width: 972.38px;
      }
      @media (max-width: 1024px) {
        width: 612.38px;
      }

      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 20px 0;

    .side-bar-menu {
      align-self: auto;
      width: fit-content;
      height: fit-content;
      margin-left: 18%;
    }
  }
}

.btn-yellow_upload-resume {
  background: rgb(239, 187, 32) !important;
  color: #fff !important;
  border: none;
}

.upload-resume__modal {
  position: relative;
  .modal-content {
    background-color: #fff;

    .upload-resume__modal-p {
      z-index: 2;
      position: relative;
      p {
        text-transform: capitalize;
      }
    }
  }

  .upload-resume__modal-icon {
    position: absolute;
    top: 30px;
    left: 41%;
    font-size: 4rem;
    color: #003763;
    z-index: 2;
  }

  .upload-resume__modal-icon-bg {
    position: absolute;
    top: -60px;
    left: 14%;
    font-size: 18rem;
    color: rgba(217, 217, 217, 0.2);
    z-index: 1 !important;
  }
}

.guZdik {
  height: 200px !important;
  border: 0 !important;
  display: block !important;
  z-index: 2 !important;
  position: relative;

  svg {
    visibility: hidden;
    margin: 0 auto 30px auto;
    display: block;
    height: 70px;
    width: 70px;
  }

  .jWkLDY > span {
    margin: 0 auto;
    font-size: 20px;
    color: #000000;
  }

  .kFhUBM span {
    text-decoration: none;
  }
}

@media screen and (max-width: 992px) {
  .background-imagePost-myresume {
    .myresume-body {
      // gap: 50px;
      display: flex;
      flex-direction: column;
      // background-color: aqua;
      margin: 50px auto;
      padding: 0 25px;

      .sidebar-container {
        width: 100%;
        margin: auto;

        .side-bar {
          width: 100%;
        }
      }
    }
  }
}
