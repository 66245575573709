.view_resume_ats {
  display: flex;

  .view_resume_ats_section {
    flex: 1;
  }
}
.screen-score,
.match-score {
  h4 {
    color: #1d9ce6;
  }

  small {
    color: #000000;
    font-weight: 700;
  }

  .view-resume__table-height {
    max-height: 200px;
    overflow-y: auto;
  }

  .match-score__btn {
    background-color: #1d9ce6;
    border: 0;
  }

  .screen-score__circle {
    max-height: 90px;
    max-width: 90px;
    text {
      font-size: 26px;
      color: #30adf3 !important;
      font-weight: 700;
    }
  }
}
