@use "../../../Abstract/" as a;

.subscriptionInformationCard {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: a.$montserat;
  h1 {
    font-size: 35px;
    font-weight: 500;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    color: #535353;
  }

  .totalJobPost {
    display: flex;
    gap: 20px;
    .totalJobPostIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 73px;
      height: 73px;
      background-color: #00cbff;
      color: #fff;
      border-radius: 100%;
      font-size: 40px;
    }

    .totalJobPostInformation {
      h1 {
        color: #00cbff;
      }
    }
  }

  .totalForInterview {
    display: flex;
    gap: 20px;
    .totalForInterviewIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 73px;
      height: 73px;
      background-color: #004f99;
      color: #fff;
      border-radius: 100%;
      font-size: 40px;
    }

    .totalForInterviewInformation {
      h1 {
        color: #004f99;
      }
    }
  }

  .totalHired {
    display: flex;
    gap: 20px;
    .totalHiredIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 73px;
      height: 73px;
      background-color: #c70000;
      color: #fff;
      border-radius: 100%;
      font-size: 40px;
    }

    .totalHiredInformation {
      h1 {
        color: #c70000;
      }
    }
  }
}

.subscription-card__ats {
  .subs-card__pro {
    box-shadow: inset 0px 8px 0px 15px #8c64d2 !important;
    border-radius: 2rem !important;
  }

  h3 {
    color: #01b5e0;
    font-size: 24px;
    font-weight: 700;
    font-family: Montserrat;
  }

  label {
    font-size: 13px;
    color: #57606a;
  }

  .btn-green {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    background: linear-gradient(0deg, #0e932b, #0e932b),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      );

    &:hover {
      color: #fff;
    }
    &:active {
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
  }
}
