@use "../../Abstract/" as a;

.customButton {
  width: 100%;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.customRoundedButton {
  width: 100%;
  border: none;
  padding: 5px;

  border-radius: 50px;
}

.customRoundedBlueButton {
  background-color: a.$lblue2;
  color: a.$white;
  font-family: a.$rubik;
  font-size: 18px;
  width: 200px;
  padding: 10px 20px;

  @media screen and (max-width: 992px) {
    width: 100%;
    background-color: a.$white;
    color: a.$violet2;
  }

  &:hover {
    background-color: a.$lblue3;
  }
}

.customRoundedVioletButton {
  width: 225px;
  background-color: a.$violet2;
  color: a.$white;
  font-family: a.$rubik;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 50px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.customRoundedBlueButton2 {
  background-color: a.$lblue2;
  color: a.$white;
  font-family: a.$rubik;
  font-size: 20px;
  width: 225px;
  padding: 10px 20px;
  border-radius: 50px;
}

@media screen and (max-width: 768px) {
  .customRoundedBlueButton2.active {
    width: 100%;
  }
}

.customRoundedBlueButtonWhiteBg {
  background-color: a.$white;
  color: a.$blue;
  font-family: a.$rubik;
  font-size: 20px;
  width: 225px;
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid a.$blue;

  @media screen and (max-width: 768px) {
    background-color: a.$white;
    width: 100%;
  }
}

.signupBtn {
  width: 200px;
  padding: 10px;
  border: 1px solid a.$dblue;
  font-family: a.$rubik;
  font-weight: 500;
  color: a.$violet2;
  background-color: transparent;
  border-radius: 50px;
}

.customLRButton {
  width: 200px;
  padding: 10px;
  border-radius: 50px;
  background-color: a.$violet2;
  color: a.$white;
  border: none;

  &:disabled {
    background-color: #ba9bff;
    cursor: not-allowed;
  }
}

.violetNoRadius {
  font-family: a.$poppins;
  background-color: a.$violet;
  color: a.$white;
  height: 64px;
}

.violetNoRadiusNoHeight {
  font-family: a.$poppins;
  background-color: a.$violet;
  color: a.$white;
}

.modalButton {
  font-family: a.$lato;
  font-weight: 700;
  color: a.$white;
  border: none;
  font-size: 16px;
}

.green {
  background-color: a.$green;
}

.red {
  background-color: a.$red2;
}

.yellow {
  background-color: a.$yellow;
}

.innerContainerBtn {
  width: fit-content;
  padding: 10px 20px;
  background-color: a.$dblue;
  color: a.$white;
  font-family: a.$lato;
  font-size: 12px;
  font-weight: 700;
}

// Container

.backNext {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.buttonCenter {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.btnContainerMrgn {
  margin: 30px 0;
}

.roundedVioletBorderBtn {
  width: 159px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid a.$violet3;
  background-color: white;

  color: a.$violet3;
  font-family: a.$lato;
  font-size: 16px;
  font-weight: 700;
}

.subscriptionBtn {
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
  color: #ffff;
}

.activeSubBtn {
  background: linear-gradient(0deg, #0e932b, #0e932b),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    );

  color: white;
}

/* Inactive Style */
.inactiveSubBtn {
  background: linear-gradient(0deg, #2fa4e8, #2fa4e8),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    );

  color: white;
}

.customDBlueBtn {
  max-width: 100px;
  background-color: #013763;
  color: #fff;
}

.container_right_btn {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}

.clientList-csv {
  background-color: a.$violet;
  color: a.$white;
  border-radius: 5px;
  max-width: 149px;
  height: 42px;
}
