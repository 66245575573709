.content-acq {
  height: 100%;
  background-image: url("assets/aqcbg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 2;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 20px;

    .subs {
      width: fit-content;
      justify-content: center;
      margin-top: 5%;

      img {
        max-width: 80%;
        max-height: 70%;
      }
    }

    .agc {
      //   width: fit-content;
      img {
        max-width: 100%;
      }
    }
  }

  .nxt_primary {
    background-color: #003763;
    color: #ffffff;
    padding: 15px 20px;
    border: 0px solid #003763;
    border-radius: 50px;
    width: 300px;
    margin: auto;
    font-size: 32px;
    font-weight: bold;
  }
  .nxt_primary:hover {
    background-color: #1a9fe5;
  }

  .landingTitle {
    font-size: 25px;
    font-weight: bold;
  }

  .imgmobile {
    display: none;
    position: relative;

    .imgDetails {
      position: absolute;
      padding: 0 20px;
      bottom: 30px;

      .title {
        color: #ffffff;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }

      .details {
        color: #ffffff;
        text-align: center;
        font-size: 16px;
      }
    }
  }

  .nxtdetails {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 50px;

    .nxtLogo {
      width: 200px;
      margin: 10px auto;
    }
  }
  .mt-10 {
    margin-top: 250px;
  }
  .rightdetails {
    position: relative;
    height: 100vh;

    .sideContent {
      width: 500px;
      display: block;
      bottom: 50px;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);

      @media (max-width: 1000px) {
        width: 300px;
      }

      @media (max-width: 769px) {
        display: none;
      }

      .title {
        color: #ffffff;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        // word-break: break-all;
        // white-space: pre-line;
      }

      .details {
        color: #ffffff;
        text-align: center;
        font-size: 18px;
      }
    }
  }
  .information {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 769px) {
  .content-acq {
    height: 100%;
    background-position: top right;
    background-image: none;
    background: #fff;

    .imgmobile {
      display: block;
    }

    .sideContent {
      display: none;
    }

    .rightdetails {
      height: 100%;
    }

    .sideContent {
      width: fit-content;
    }

    .nxt_primary {
      font-size: 24px;
      width: 100%;
    }

    .logo {
      width: 100%;
      flex-direction: column;
      gap: 10px;

      .subs {
        margin-top: 0%;
        img {
          width: 230px;
          max-height: 90px;
        }
      }
    }
    .nxtdetails {
      padding-bottom: 0;
      .nxtLogo {
        margin-top: 30px;
      }
    }
    .agc img {
      max-width: 100%;
      max-height: 90px;
      display: -webkit-box;
      margin: auto !important    ;
    }

    .mt-10 {
      margin-top: 50px;
    }
    .ms-3 {
      margin: 0 !important;
    }
    .padd0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  //   .rightdetails {
  //     position: relative;
  //     height: 0vh;
  //     background: #fff;
  //   }
  //   .aqcbg {
  //     background: #fff;
  //   }
}

@media screen and (max-width: 300px) {
  .content-acq {
    .logo {
      .subs {
        img {
          width: 100%;
        }
      }
    }
  }
}
