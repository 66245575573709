.sidebar-va-container {
  width: 268px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 20px;
  background: #ffff;

  margin-bottom: 20px;

  .sva-header {
    h5 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #002848;
    }

    h6 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
    }
  }

  p {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin-top: 15px;
  }
}
