.sidebar-vr-container {
  width: 268px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 20px;
  background: #ffff;

  h5 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #002848;
  }

  select {
    margin: 20px 0;
    border: 1px solid #e1e6eb;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  button {
    width: 100%;
    background: #3085d6;
    border-radius: 5px;
    border: none;

    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
  }

  button:hover {
    background: #3085d6;
  }
}

@media (max-width: 1200px) {
  .sidebar-vr-container {
    width: 100%;
  }
}
