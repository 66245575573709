.header-bg {
  display: flex;
  background-color: #ffffff;
  padding: 10px;
  align-items: center;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);

  .nxt-logo {
    width: 168px;
    height: 60px;
    cursor: pointer;
  }
  .talent-list-btn,
  .job-list-btn {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #003763;
    text-align: left;
    cursor: pointer;
  }
}

.nav-menu {
  margin-right: auto;
  margin-left: 20px;
  font-size: 14px;
  text-transform: uppercase;
  gap: 10px;
  align-items: center;
}

.talent-list-btn.active {
  color: #003763 !important;
  border-radius: 3px;
  background-color: #e1e7ff;
}

#dropdown-menu {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  // color: #003763;
  color: #ffffff;
  background: #01b5e0;
  border-radius: 3px;
}

.btn-company__list-btn {
  border: 1px solid #2c97eb !important;
  color: #2c97eb !important;
}

@media screen and (max-width: 990px) {
  .nav-menu {
    margin-top: 10px;
    margin-left: 0px;

    li {
      width: 100%;
    }
    // padding: 0;
    // column-gap: 20px;
  }
  #dropdown-menu {
    text-align: left;
    background-color: transparent;
    color: #003763;
  }
  #dropdown-option {
    border: none;
    background: none;

    button {
      color: white;
      color: #003763;
    }
  }
  .post-a-job-btn {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
