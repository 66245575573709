.joblistcontent-container {
  .header {
    display: flex;
    justify-content: space-between;
    margin: auto;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    div {
      h1 {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        color: #003763;
        padding: 0;
        word-break: break-all;
        white-space: pre-line;
        margin: 0;
      }

      h3 {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        color: #232323;
        word-break: break-all;
        white-space: pre-line;
      }

      button {
        background: #013763;
        border-radius: 3px;
        border: none;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 10px;
      }
    }

    .socialMediaContainer {
      margin: 0;
      padding: 0;
    }
  }

  .body {
    .job-details {
      min-height: 100px;
      margin-top: 10px;
      hr {
        margin: 0;
      }
      h1 {
        word-break: break-all;
        white-space: pre-line;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #013763;
      }

      p,
      li {
        word-break: break-all;
        white-space: pre-line;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #232323;
        margin-top: 10px;
        word-break: break-all;
      }
    }
  }
}

// @media screen and (max-width: 992px) {
//   .joblistcontent-container {
//     .header {
//       display: block;
//       // justify-content: space-between;

//       // button {
//       //   margin: 30px auto;
//       // }
//     }
//   }
// }
