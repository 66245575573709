.talentlist-client {
  width: 100%;
  // padding: 30px;

  h6 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #003763;
  }

  .applicant-details-container {
    display: flex;
    background: #f9faff;
    border-left: 4px solid #dfe7ff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      background: #d9d9d9;
      border-radius: 7px;
      margin-right: 10px;
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      h4 {
        // align-items: center;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        color: #003863;
        margin: 0;
        cursor: pointer;
      }

      .additional-details {
        display: flex;

        h5 {
          margin: 0;
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          color: #232323;
          width: 200px;
          margin-right: 20px;
        }

        .information {
          display: flex;
          gap: 10px;
          //   background-color: #c2c2c2;
          margin: auto 0;

          .information-group {
            display: flex;
            align-items: center;

            gap: 5px;
            .icon {
              font-size: 14px;
              color: #003863;
            }

            h6 {
              margin: auto 0;
              font-family: "Lato", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #003863;
            }
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #6f6f6f;
    }

    button {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #666666;

      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 3px;
    }
  }
}
