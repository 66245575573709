.subsription-background-imagePost {
  background-image: url("../../../../../public/images/acquiro-element-bg.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .header-fixed + .banner {
    padding-top: 90px;
  }

  .body-container {
    gap: 50px;
    display: flex;
    margin: 50px auto;
    padding: 0 auto;

    .sidebar-container {
      top: 100px;
      position: sticky;
    }

    .content-container {
      width: 100%;
      height: 100%;
      // display: inline-block;
    }
  }

  .subscribed__btn-purple {
    color: #8046fa;
    border: 1px solid #8046fa;

    &:hover {
      background-color: #8046fa !important;
      border: 1px solid #8046fa;
    }
  }
}

@media screen and (max-width: 768px) {
  .subsription-background-imagePost {
    .body-container {
      // gap: 50px;
      display: flex;
      flex-direction: column;
      // background-color: aqua;
      margin: 50px auto;
      padding: 0 25px;

      .sidebar-container {
        width: 100%;
        margin: auto;
      }
    }
    .content {
      display: flex;
      align-items: center;
      gap: 50px;
      padding: 20px 0;

      .side-bar-menu {
        align-self: auto;
        width: fit-content;
        height: fit-content;
        margin-left: 18%;
      }
    }
  }
}
